import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
    const auth = localStorage.getItem('ClientAuth');
    return auth === "true" ? children : <Navigate to="/denied" />;
}

export default PrivateRoute;

