import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../Pages/Dashboard";
import Login from "../Pages/Login";
import PrivateRoute from "../Hooks/ClientAuth";
import Erro404 from "../Pages/404";
import Denied from "../Pages/Denied";

const AppRoutes = () => (
    <Routes>
        <Route exact path="/authenticate/:token" element={<Login />} />
        <Route exact path="/denied" element={<Denied />} />
        <Route path="" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path='*' element={<Erro404 />} />
    </Routes>
);

export default AppRoutes;