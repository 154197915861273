import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

let translate = "en";

if (localStorage.getItem('officeLang') === "en_US" || localStorage.getItem('officeLang') === "en") {
    translate = "en"
}
if (localStorage.getItem('officeLang') === "es_ES" || localStorage.getItem('officeLang') === "es") {
    translate = "es"
} if (localStorage.getItem('officeLang') === "pt_BR" || localStorage.getItem('officeLang') === "pt") {
    translate = "pt"
} if (localStorage.getItem('officeLang') === "de" || localStorage.getItem('officeLang') === "de_DE") {
    translate = "de"
} if (localStorage.getItem('officeLang') === "fr" || localStorage.getItem('officeLang') === "fr_FR") {
    translate = "fr"
} if (localStorage.getItem('officeLang') === "it" || localStorage.getItem('officeLang') === "it_IT") {
    translate = "it"
} if (localStorage.getItem('officeLang') === "ru") {
    translate = "ru"
}


i18n
    //Habilita o i18next
    .use(Backend)
    // Detecção automatica de linguagem
    .use(LanguageDetector)
    //Inicialização do hook
    .use(initReactI18next)
    .init({
        //linguagem padrão 
        fallbackLng: translate,
        debug: true,
        //Guarda em cache a linguagem escolhida
        detection: {
            order: ['queryString', 'cookie'],
            cache: ['cookie']
        },
        interpolation: {
            escapeValue: false
        }
    })

export default i18n;