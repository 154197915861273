import React from "react";
import Image404 from '../../Assets/Images/404.svg';
import { useTranslation } from "react-i18next";

const Erro404 = () => {
    const { t } = useTranslation();
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', }}>
            <div className='ba-card ba-300'>
                <img src={Image404} alt='image_alt' className='ba-image' />
                <span className='ba-title'>{t("words.404")}</span>
                <span className='ba-description'>{t("words.page_doesnt_exist")}</span>
                <button className='ba-btn ba-md ba-green' onClick={() => window.open('https://office.builderall.com/')}>
                    {t("words.back_office")}
                </button>
            </div>
        </div>
    )
}

export default Erro404;