import React from "react";


export const GeralContext = React.createContext({});

export const GeralProvider = (props) => {

    const [launchNum, setLaunchNum] = React.useState([]);

    const [bazapNum, setBazapNum] = React.useState([]);


    return (
        <GeralContext.Provider value={{ launchNum, setLaunchNum, bazapNum, setBazapNum }}>
            {props.children}
        </GeralContext.Provider>
    )
}

export const useGeral = () => React.useContext(GeralContext);