import React from "react";
import ImageDenied from '../../Assets/Images/denied.svg';
import { useTranslation } from "react-i18next";

const Denied = () => {
    const { t } = useTranslation();
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', }}>
            <div className='ba-card ba-300'>
                <img src={ImageDenied} alt='image_alt' className='ba-image' />
                <span className='ba-title'>{t("words.not_authorized")}</span>
                <span className='ba-description'>{t("words.need_login")}</span>
                <button className='ba-btn ba-md ba-green' onClick={() => window.open('https://office.builderall.com/')}>
                    {t("words.back_office")}
                </button>
            </div>
        </div>
    )
}

export default Denied;