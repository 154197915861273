import React from 'react'
import { ReactComponent as Qr } from '../../Assets/Icons/qr.svg';
import { ReactComponent as Whats } from '../../Assets/Icons/whats.svg';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';
import DateFormat from '../../Components/DateFormat';
import { getNumberBazap, getNumbers } from '../../Services/numbers';
import { useGeral } from '../../Providers/geral';
import Over from '../../Assets/Images/overviw2.png';
import './styles.css'

const Dashboard = () => {

    const { t } = useTranslation();
    const { bazapNum, launchNum } = useGeral();
    const [launchNumberList, setLaunchNumberList] = React.useState(launchNum);
    const [bazapNumber, setBazapNumber] = React.useState(bazapNum);
    const [search, setSearch] = React.useState('');

    const getLaunchNumber = async () => {
        try {
            const response = await getNumbers();
            setLaunchNumberList(response);
        } catch (error) {

        }
    }
    const getBazapNumber = async () => {
        try {
            const response = await getNumberBazap();
            setBazapNumber(response);
        } catch (error) {

        }
    }

    React.useEffect(() => {
        if (bazapNum.length <= 0 || launchNum <= 0) {
            getLaunchNumber();
            getBazapNumber();
        }
    }, [])

    const liStyles = {
        ':hover': {
            color: '#0060E3'
        },
    };
    return (
        <>
            <builderall-banner
                title={t("words.whatsapp_central")}
                subtitle={t("words.all_tools")}
                description={t("words.use_whats")}
            >
            </builderall-banner>

            <div className='ba-app-wrapper ba-gp-1' >
                <div className='ba-sidebar'>
                    <ul className='ba-nav'>
                        <li className='ba-nav-item'>
                            <a href='' className='ba-link ba-is-active'>
                                <Whats />
                                <span className='ba-title'>{t("words.dashboard")}</span>
                            </a>
                        </li>
                    </ul>
                    <div className='ba-bottom'>
                        <div className='ba-overview' style={{ display: 'none' }}>
                            <div className='ba-video-thumb'>
                                <img src={Over} alt='Overview central' />
                                <button className='ba-button'>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrows-angle-expand' viewBox='0 0 16 16'>
                                        <path fillRule='evenodd' d='M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z' />
                                    </svg>
                                </button>
                            </div>
                            <span className='ba-title'>Overview {t("words.whatsapp_central")} </span>
                            <span className='ba-description'>{t("words.overview_info")}</span>
                            <button className='ba-btn ba-sm ba-blue ba-w-100' onClick={() => window.open('https://knowledgebase.builderall.com/docs-category/whatsappcentral')}>
                                {t("words.see_more")}
                                <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='#fff' className='bi bi-arrow-up-right' viewBox='0 0 16 16'>
                                    <path fillRule='evenodd' d='M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z' />
                                </svg>
                            </button>
                        </div>
                        <ul className='ba-bottom-list'>
                            <li style={liStyles}>
                                <builderall-help></builderall-help>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='ba-container ba-grid ba-gp-1'>
                    <div className='ba-card' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ width: '50%', height: '100%' }}>
                            <h6 className='ba-montserrat-700 ba-color-dark5'>{t("words.bazap_tool")}</h6>
                            <br />
                            <p className='ba-color-black9 ba-montserrat-400' style={{ fontSize: '0.9rem' }}>
                                {t("words.bazap_explain")}
                            </p>
                        </div>
                        <div className='ba-flex ba-gp-08 ba-f-column' style={{ width: '50%', height: '100%', }}>
                            {bazapNumber?.number ?
                                <div className='ba-flex ba-gp-04 ba-jc-fe' style={{ width: '100%', height: '100%', display: 'flex', }}>
                                    <div ><span className='ba-montserrat-700 ba-color-dark5	'>{t("words.number")} #:  {formatPhoneNumberIntl('+' + bazapNumber?.number)}</span></div>
                                    <div ><small className={`ba-circle ${bazapNumber?.connected === true ? 'ba-green' : 'ba-red'}`}></small></div>
                                    <div className='ba-gp-06' style={{ display: 'flex' }}>
                                        <div style={{}}>
                                            <span className={`ba-montserrat-700 ${bazapNumber?.connected === true ? 'ba-color-green1' : 'ba-color-red1'}`}> {bazapNumber?.connected === true ? t("words.conected") : t("words.disconnected")}</span>
                                        </div>
                                        <div style={{ cursor: 'pointer', flex: '1', justifyContent: 'flex-end', }}>
                                            <Qr />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='ba-flex ba-gp-04 ba-jc-fe' style={{ width: '100%', height: '100%', display: 'flex', }}>
                                    <span className={`ba-montserrat-700 ba-color-red1`}> {t("words.disconnected")}</span>
                                    {/* <div ><span className='ba-montserrat-700 ba-color-dark5' style={{ fontSize: '0.9rem' }}> {t("words.test_failed")}</span></div> */}
                                </div>
                            }
                            <div className='ba-flex ba-jc-fe'>
                                <button className='ba-btn ba-md ba-blue' onClick={() => window.open(`${process.env.REACT_APP_BAZAP}`, '_self')}>
                                    {t("words.access")} {t("words.tool")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='ba-card' style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ width: '60%', height: '100%', }}>
                                <h6 className='ba-montserrat-700 ba-color-dark5'>{t("words.launch_tool")}</h6>
                                <br />
                                <p className='ba-color-black9 ba-montserrat-400' style={{ fontSize: '0.9rem' }}>
                                    {t("words.launch_explain")}
                                </p>
                            </div>
                            <div className='ba-flex ba-gp-1 ba-f-column' style={{ width: '40%', height: '100%', }}>
                                <div className='ba-flex ba-jc-fe'>
                                    {launchNumberList.length <= 0 ?
                                        <button className='ba-btn ba-md ba-blue' onClick={() => window.open(`${process.env.REACT_APP_LAUNCH}/authenticate/${localStorage.getItem('primaryToken')}/?redir=authenticate`, '_self')}>
                                            {t("words.access")} {t("words.launch_tool")}
                                        </button>
                                        :
                                        <button className='ba-btn ba-md ba-blue' onClick={() => window.open(`${process.env.REACT_APP_LAUNCH}/authenticate/${localStorage.getItem('primaryToken')}/?redir=campaigns/dashboard`, '_self')}>
                                            {t("words.access")} {t("words.tool")}
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '100%' }}>
                            {launchNumberList.length <= 0 ? <div>
                                <p className='ba-color-black9 ba-montserrat-400'>
                                    {t("words.test_failed")}
                                </p>
                            </div> :
                                <table className='ba-table ba-md ba-lines' style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'center' }}>{t("words.number")}#</th>
                                            <th style={{ textAlign: 'center' }}>{t("words.status")}</th>
                                            <th style={{ textAlign: 'center' }}>{t("words.created")}</th>
                                            <th style={{ textAlign: 'center' }}>
                                                <div className='ba-box-search-input' style={{ background: '#FFFFFF' }}>
                                                    <input type='search' className='ba-input' placeholder='Search...' onChange={(e) => setSearch(e.target.value)} value={search} />
                                                    <button className='ba-button'>
                                                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-search' viewBox='0 0 16 16'>
                                                            <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {launchNumberList?.filter((list) => {
                                            if (search === "") {
                                                return list
                                            } else if (list?.number.toLowerCase().includes(search.toLowerCase())) {
                                                return list
                                            } else {
                                                return null
                                            }
                                        }).map((list) => (
                                            <tr key={list?.id} style={{ textAlign: 'center', alignContent: 'center' }}>
                                                <td className='ba-double'>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', textAlign: 'center', }}>
                                                        <p className='ba-subtitle ba-montserrat-700 ba-color-dark5'>{formatPhoneNumberIntl('+' + list?.number.split('@')[0])}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='ba-subtitle ba-flex ba-gp-06 ba-jc-center' style={{}}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}> <span className={`ba-circle ${list?.connected === true ? 'ba-green' : 'ba-red'}`}></span></div>
                                                            &nbsp;
                                                            <p className={`ba-montserrat-700 ${list?.connected === true ? 'ba-color-green1' : 'ba-color-red1'}`}>{list?.connected === false ? t("words.disconnected") : t("words.conected")}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td >
                                                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                                                        <p className='ba-subtitle ba-montserrat-700 ba-color-dark5'><DateFormat date={list?.created_at} /></p>
                                                    </div>
                                                </td>
                                                <td >
                                                    <div className='ba-flex ba-gp-2 ba-jc-fe'>
                                                        <div onClick={() => window.open(`${process.env.REACT_APP_LAUNCH}/authenticate/${localStorage.getItem('primaryToken')}/?redir=campaigns/dashboard/${list?.id}`, '_self')} ba-tooltip-position='top' ba-tooltip-title={t("words.campaigns")} >
                                                            <button class='ba-btn ba-btn-normal ba-sm ba-rounded' >
                                                                <builderall-icon code='b456' />
                                                            </button>
                                                        </div>
                                                        <div onClick={() => window.open(`${process.env.REACT_APP_LAUNCH}/authenticate/${localStorage.getItem('primaryToken')}/?redir=attendance/dashboard/${list?.id}`, '_self')} ba-tooltip-position='top' ba-tooltip-title={t("words.attendance")}>
                                                            <button class='ba-btn ba-btn-normal ba-sm ba-rounded'>
                                                                <builderall-icon code='3cd8' />
                                                            </button>
                                                        </div>
                                                        <div onClick={() => window.open(`${process.env.REACT_APP_LAUNCH}/authenticate/${localStorage.getItem('primaryToken')}/?redir=numbers`, '_self')} ba-tooltip-position='top' ba-tooltip-title={t("words.my_numbers")}>
                                                            <button class='ba-btn ba-btn-normal ba-sm ba-rounded'>
                                                                <builderall-icon code='93f0' />
                                                            </button>
                                                        </div>
                                                        {/*  <div ba-tooltip-position='top' ba-tooltip-title='Tooltip'>
                                                                <img src={Delete} alt='delete' />
                                                            </div> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            }

                        </div>

                    </div>
                </div>
                <div class='ba-footer'>
                    <builderall-footer></builderall-footer>
                </div>
            </div>
        </>
    )
}

export default Dashboard;