import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { GeralProvider } from './Providers/geral';
import './i18n';


ReactDOM.render(
  <Suspense fallback={<div style={{ textAlign: 'center', height: '50vh', marginTop: '50vh' }}>
    <div className="spinner-grow text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>}>
    <GeralProvider>
      <App />
    </GeralProvider>
  </Suspense >,
  document.getElementById('root')
)