import React from "react";
import LiquidLoader from "../../Assets/Animation/Loader";
import { useParams, useNavigate } from "react-router-dom";
import { loginAuth } from "../../Services/login";

const Login = () => {
    let { token } = useParams();
    const navigate = useNavigate();
    const [error, setError] = React.useState(false);

    const auth = async () => {
        try {
            localStorage.setItem('primaryToken', token);
            const response = await loginAuth(token);
            if (response?.token) {
                localStorage.setItem('token', response.token);
                localStorage.setItem('ClientAuth', 'true');
                navigate('/')
            } else {
                localStorage.setItem('ClientAuth', 'false');
            }
        } catch (error) {
            localStorage.setItem('ClientAuth', 'false');
            setError(true);
        }
    }
    React.useEffect(() => {
        auth();
    }, [localStorage.getItem('token')])

    return (
        <div>
            {!error ?
                <div style={{ textAlign: 'center' }}>
                    <builderall-loader loading='true'>
                        <div style={{ width: '25rem', height: '25rem' }}>
                            Login
                        </div>
                    </builderall-loader>
                    Login
                </div> :
                <div>
                    <p>Não foi possivel logar</p>
                </div>
            }

        </div>
    )
}

export default Login;